import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import TableComponent from "../components/TableComponent";

// Actions
import { getAuthLogsData } from "../store/actions/authLogsActions";

const tableColumns = [
  { label: "ID", key: "id" },
  { label: "IP", key: "ip" },
  { label: "User", key: "user.first_name" },
  { label: "Email", key: "user.email" },
  { label: "Created", key: "created_at.date", isTime: true },
  { label: "User Agent", key: "user_agent" },
];

export default function AuthLogs() {
  const dispatch = useDispatch();
  const authLogsDataObj = useSelector(state => state.authLogs.authLogsDataObj);
  const authLogsDataLoading = useSelector(state => state.authLogs.authLogsDataLoading);

  useEffect(() => !Object.keys(authLogsDataObj).length && getAuthLogsData(1, dispatch), []);

  const getDataForNewPage = newPage => getAuthLogsData(newPage + 1, dispatch);

  return (
    <TableComponent
      dataIsLoading={authLogsDataLoading}
      columns={tableColumns}
      generalData={authLogsDataObj}
      onPageChange={getDataForNewPage}
    />
  );
}
