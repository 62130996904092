import { Environment } from "../js/environment";
import axios from "axios";
import getCookie from "../js/getCookie";

// Store
import store from "../store/store";

// Actions
import { logoutUserIfSessionHasEnded } from "../store/actions/loginActions";

export const endpoints = {
  Login: {
    path: Environment.profileApi + "/login",
    method: "POST",
  },
  GetRoles: {
    path: Environment.profileApi + "/roles",
    method: "GET",
  },
  AddRole: {
    path: Environment.profileApi + "/roles",
    method: "POST",
  },
  UpdateRole: {
    path: Environment.profileApi + "/roles",
    method: "PUT",
  },
  DeleteRole: {
    path: Environment.profileApi + "/roles",
    method: "DELETE",
  },
  GetCompanies: {
    path: Environment.profileApi + "/companies",
    method: "GET",
  },
  AddCompany: {
    path: Environment.profileApi + "/companies",
    method: "POST",
  },
  UpdateCompany: {
    path: Environment.profileApi + "/companies",
    method: "PUT",
  },
  DeleteCompany: {
    path: Environment.profileApi + "/companies",
    method: "DELETE",
  },
  GetUsers: {
    path: Environment.profileApi + "/users",
    method: "GET",
  },
  UpdateUser: {
    path: Environment.profileApi + "/users",
    method: "PUT",
  },
  searchUser: {
    path: Environment.profileApi + "/users/search",
    method: "GET",
  },
  DeleteUser: {
    path: Environment.profileApi + "/users",
    method: "DELETE",
  },
  ActivateUser: {
    path: Environment.profileApi + "/users",
    method: "POST",
  },
  GetAuthLogs: {
    path: Environment.profileApi + "/auth_logs",
    method: "GET",
  },
  GetServiceCenters: {
    path: Environment.serviceApi + "/services-centers",
    method: "GET",
  },
  GetServiceCenter: {
    path: Environment.serviceApi + "/services-centers",
    method: "GET",
  },
  CreateServiceCenter: {
    path: Environment.serviceApi + "/services-centers",
    method: "POST",
  },
  UpdateServiceCenter: {
    path: Environment.serviceApi + "/services-centers",
    method: "PATCH",
  },
  DeleteServiceCenter: {
    path: Environment.serviceApi + "/services-centers",
    method: "DELETE",
  },
};

export class TransportService {
  static getEndpoint(endpoint) {
    try {
      return endpoints[endpoint];
    } catch (err) {
      throw new Error(`An unknown API endpoint: ${endpoint}`);
    }
  }

  // Axios Get Request
  requestData = async (command, data, queryParams, pathParams) => {
    const authorization = `Bearer ${getCookie(Environment.token)}`;
    const endpoint = TransportService.getEndpoint(command);
    const requestPath = `${endpoint.path}${pathParams ? "/" + pathParams : ""}`;
    const requestMethod = endpoint.method;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
      params: queryParams,
    };

    // Check Request Type
    if (requestMethod === "GET") {
      // Use for GET method
      return await axios
        .get(requestPath, config)
        .then(response => response.data)
        .catch(error => error.response.status === 401 && store.dispatch(logoutUserIfSessionHasEnded()));
    } else {
      // Use methods like: POST, PATCH, DELETE, PUT
      return await axios({
        method: requestMethod,
        url: requestPath,
        data: data,
        ...config,
      })
        .then(response => response.data)
        .catch(error => error.response.status === 401 && store.dispatch(logoutUserIfSessionHasEnded()));
    }
  };
}
