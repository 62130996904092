import React from "react";
import UserSearch from "./components/UserSearch";
import UsersList from "./UsersList";

export default function UsersPage() {
  return (
    <>
      <UserSearch />
      <UsersList />
    </>
  );
}
