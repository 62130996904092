import React from "react";
import { useDispatch } from "react-redux";
import { SimpleInput } from "../../../components/formElements";
import SearchIcon from "@material-ui/icons/Search";

// Actions
import { searchUser, fetchUsers } from "../../../store/actions/usersActions";

export default function UserSearch() {
  const dispatch = useDispatch();

  const handleSearchUserByName = event => {
    event.target.value.length > 2 && searchUser(`name/${event.target.value}`, dispatch);
    event.target.value.length === 0 && fetchUsers(1, dispatch);
  };
  const handleSearchUserByEmail = event => {
    event.target.value.length > 2 && searchUser(`email/${event.target.value}`, dispatch);
    event.target.value.length === 0 && fetchUsers(1, dispatch);
  };

  return (
    <div className="form-block">
      <div className="form-item">
        <div className="__with-icon">
          <SearchIcon />
          <SimpleInput label="Search by name" onChange={handleSearchUserByName} />
        </div>
      </div>

      <div className="form-item">
        <div className="__with-icon">
          <SearchIcon />
          <SimpleInput label="Search by email" onChange={handleSearchUserByEmail} />
        </div>
      </div>
    </div>
  );
}
